import React from "react";
import PropTypes from "prop-types";
import InternalLinkItem from "./internal-link-item";

const InternalLinks = ({ internalLinks }) => {
  if (!Array.isArray(internalLinks)) {
    return null;
  }
  return (
    <div className="internal-link">
      {internalLinks.map((linkItem) => (
        <InternalLinkItem {...linkItem} />
      ))}
      <style jsx>{`.internal-link {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 0.75rem 0rem;
}

@media screen and (min-width: 768px) {
  .internal-link {
    gap: 2rem;
  }
}

@media screen and (min-width: 1024px) {
  .internal-link {
    gap: 3rem;
  }
}`}</style>
    </div>
  );
};

export default InternalLinks;

InternalLinks.propTypes = {
  /**
   * {
   *  "info": "Dirección URL a la que lleva el link.",
   *  "kind": "both",
   *  "beautifulName": "Link URL"
   * }
   */
  link: PropTypes.string,

  /**
   * {
   *  "info": "Texto del link",
   *  "kind": "both",
   *  "beautifulName": "Texto de el Link"
   * }
   */
  text: PropTypes.string,

  /**
   * {
   *  "info": "Etiqueta HTML target que se incluye en el link",
   *  "kind": "both",
   *  "beautifulName": "Target"
   * }
   */
  target: PropTypes.oneOf(["_blank", "_self", "_parent", "_top"]),
  title: PropTypes.string,
  ariaLabel: PropTypes.string,
};

InternalLinks.defaultProps = {
  title: "",
  ariaLabel: "",
  link: "",
  text: "",
  target: "_self",
};

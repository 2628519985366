import React from "react";
import PropTypes from "prop-types";
import CustomLink from "@/bit/components/components.custom-link";
import Glyph from "@/bit/components/components.icon-glyph";
import { TAGGIN_INFO } from "../constants";

const MainLogo = ({ link, logo, target, ariaLabel, title }) => {
  return (
    <>
      <div className="main-logo">
        <CustomLink
          taggingInfo={{ ...TAGGIN_INFO, componentContent: "Telefónica" }}
          href={link} 
          target={target}
          cyData={`Footer telefonica logo`}
          ariaLabel={ariaLabel}
          title={title}
        >
          <Glyph
            id={logo}
            width={158}
            height={41}
            color="grey3"
          />
        </CustomLink>
      </div>
      <style jsx>{`.main-logo {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.25rem 0;
}
.main-logo :global(.svg-layout-size) {
  display: flex;
  align-items: center;
}

.main-logo :global(img:hover) {
  opacity: 0.75;
}
@media screen and (min-width: 768px) {
  .main-logo {
    padding: 1.75rem 0;
  }
}
`}</style>
    </>
  );
};

export default MainLogo;

MainLogo.propTypes = {
  /**
   * {
   *  "info": "URL del logo",
   *  "kind": "both",
   *  "beautifulName": "URL del logo"
   * }
   */
  link: PropTypes.string,

  /**
   * {
   *  "info": "Target de la URL",
   *  "kind": "both",
   *  "beautifulName": "Target de la URL"
   * }
   */
  target: PropTypes.oneOf(["_blank", "_self", "_parent", "_top"]),
  title: PropTypes.string,
  ariaLabel: PropTypes.string,
};

MainLogo.defaultProps = {
  link: "",
  target: "_self",
  title: "",
  ariaLabel: ""
};

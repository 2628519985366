import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";
import IconGlyph from "@/bit/components/components.icon-glyph";
import Price, { PRICE_SIZE } from "@/bit/components/components.price";
import CustomLink from "@/bit/components/components.custom-link";
import getColor from "@gcloud-npm/utils.enums.colors";
import Image from "@/bit/components/components.c-image";
import TextInfo, {
  TEXT_COLOR,
  TEXT_FONT_WEIGHT,
  TEXT_HEADING_LEVEL,
  TEXT_KIND,
  TEXT_SIZE,
  TEXT_DISPLAY,
} from "@/bit/components/components.text";
import SearchInput, { handleAtRequest } from "../../searcher/search-input";
import { actions as mainActions } from "@/bit/reducers/reducers.main-reducer";
import { actions as modalActions } from "@/bit/reducers/reducers.modals-reducer";
import { actions as headerActions } from "@/bit/reducers/reducers.header-reducer";

const INPUT_ID = "inputsupportadnsearchmobile";
const PAGE = "page";
const PAGE_TITLE = "Contenidos relacionados";
const DEVICE = "device";
const DEVICE_TITLE = "Dispositivos relacionados";
const SERVICE = "service";
const SERVICE_TITLE = "Servicios relacionados";

const DEVICE_PRICE = {
  color: TEXT_COLOR.BLUE_DARK,
  size: PRICE_SIZE.XXXS,
  fontType: TEXT_FONT_WEIGHT.BOLD,
};

const TITLE_RESULT_SECTION_PROPS = {
  level: TEXT_HEADING_LEVEL.H3,
  kind: TEXT_KIND.HEADING,
  fontType: TEXT_FONT_WEIGHT.REGULAR,
  size: TEXT_SIZE.BODY1,
  display: TEXT_DISPLAY.BLOCK,
};
const RESULT_TEXT_INFO_PROPS = {
  color: TEXT_COLOR.GREY_4A,
  size: TEXT_SIZE.BODY1,
  fontType: TEXT_FONT_WEIGHT.LIGHT,
  kind: TEXT_KIND.SPAN,
};

const taggingInfo2AT = {
  componentName: "searcher",
  componentProduct: "",
  componentVariation: "asistente-virtual",
  componentPerso: "",
};

const colorBlue = getColor("BLUE");

const StyledSupportAndSearch = styled.div`
  position: relative;
  width: 100vw;
  max-width: 1000px;
  margin: 0 auto;
  background-color: white;
  position: relative;
  border-radius: 0px 0px 10px 10px;
  max-height: calc(100vh - 5rem);
  overflow-y: auto;
  .SearchInput {
    width: 100%;
    padding: 0rem 1rem;
    height: auto;
    border: 1px solid #c9c9c9;
    border-radius: 1.75rem;
    display: flex;
    flex-wrap: nowrap;
    transition: all 0.3s;
    button {
      display: flex;
      align-items: center;
    }
    &.focus {
      border-color: ${colorBlue};
      transition: all 0.3s;
    }

    .field {
      width: 100%;
      border: none;
      height: 2.75rem;
      outline: none;
      padding-left: 1.25rem;
    }

    .field:focus {
      border: none;
      outline: none;
    }

    .clearButton:focus {
      border: none;
      outline: none;
    }
  }
  .SuggestedItem {
    display: flex;
    align-items: center;
    flex-direction: row;
    height: auto;
    min-height: 2rem;
    width: 100%;
    flex-wrap: nowrap;
    .suggested-label {
      padding-left: 0.25rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 2;
      max-width: calc(100% - 16px);
    }
    .svg-layout-size {
      display: flex;
    }
  }
  .SearcherResultsGaps button {
    display: block;
  }

  .ResultsSection.page {
    background-color: #f6f6f6;
    border-radius: 0px 0px 10px 10px;
    button {
      display: block;
    }
    .PageResultsContainer {
      width: 100%;
      max-width: 1000px;
      padding: 1rem 0px 0.5rem 0px;
      margin: 0 auto;
    }
    .PageNoResultsContainer {
      width: 100%;
      max-width: 1000px;
      padding: 1rem 0px 0.5rem 0px;
      margin: 0 auto;
    }
    .PageResultsContainer {
      .SearcherResultsGaps {
        padding: 0.5rem 0px 0px 0px;
      }
    }
  }

  .SupportAndSearchContainer {
    padding: 0rem 1rem;
    .sectionTitle {
      margin-top: 0.625rem;
      margin-bottom: 1.5625rem;
    }
    .e-input {
      margin-bottom: 2rem;
    }
    .buttonsContainer {
      width: 100%;
      max-width: 500px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      margin-bottom: 1.25rem;
      .priorityButton {
        margin-bottom: 0.625rem;
      }
    }
    .titleLinks {
      display: block;
      padding-bottom: 0.3125rem;
    }
    .linksListContainer {
      padding-bottom: 1rem;
      .results-t {
        display: none;
        padding-bottom: 0.5rem;
      }
    }

    .arrayLinksContainer {
      margin-top: 0.5rem;
      display: row;
      flex-direction: column;
      justify-content: left;
      flex-wrap: wrap;
      .itemLink {
        text-align: left;
        outline: none;
      }
    }
    .SuggestedInput {
      padding: 0.5rem 0rem;
      button {
        display: block;
        padding-top: 0.25rem;
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:first-child {
          padding-top: 0rem;
        }
      }
    }
    .SearcherGap {
      padding: 0.5rem 0rem 0.5rem 0rem;
    }
    .SearcherNoResults {
      padding: 0.5rem;
      p {
        margin-top: 0.5rem;
      }
    }
    .ResultsSection {
      width: 100%;
      padding-top: 0.5rem;
      .SearcherResultsGaps {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
        padding: 0px;
        button {
          display: inline-grid;
        }
      }
    }

    .ServiceGap,
    .DeviceGap {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      height: 100%;
      max-height: 6rem;
      flex-direction: row;
      align-items: stretch;
      justify-content: space-between;
      overflow: hidden;
      box-shadow: 0rem 0rem 0.1rem #c7c7c7;
      border-radius: 10px;
      padding: 0.5rem;
    }

    .DeviceGap .DevicePicture {
      position: relative;
      height: 100%;
      width: 100%;
      max-width: 33%;
      display: block;
      margin: 0 auto;
      overflow: hidden;
      img {
        max-height: 100%;
        width: auto;
      }
      .relative2fill {
        opacity: 0;
      }
      .absolute2show {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .ServiceGap .ServiceDetails,
    .DeviceGap .DeviceDetails {
      width: 100%;
      padding-left: 0.5rem;
      max-width: 67%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    .ServiceGap {
      max-height: none;
      .ServiceDetails {
        max-width: 100%;
      }
    }
    .ServiceGap .ServiceDetails .DeviceName,
    .DeviceGap .DeviceDetails .DeviceName {
      height: 2.1rem;
      line-height: 1rem;
      overflow: hidden;
      margin-bottom: 0.25rem;
      p {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        height: 100%;
        line-height: inherit;
      }
    }

    .DeviceGap .DeviceDetails .ServicePrice,
    .DeviceGap .DeviceDetails .DevicePrice {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: left;
      align-items: baseline;
      margin-top: 0.5rem;
    }

    .DeviceGap .DeviceDetails .DevicePrice .prePriceText {
      margin-right: 0.5rem;
    }
  }

  .itemLink .itemLink-element,
  .ResultsSection.page
    .PageResultsContainer
    .SearcherResultsGaps
    .itemLink
    .itemLink-element {
    display: flex;
    height: 2rem;
    justify-content: left;
    align-items: center;
    margin: 0px 0px 0.5rem 0px;
    .itemLink-element-icon {
      display: flex;
      padding: 0.5rem;
    }
  }

  .form-searcher {
    display: flex;
  }
  .form-searcher .backbutton {
    margin-right: 1rem;
  }

  @media screen and (max-width: 390px) {
    .DeviceGap .DeviceDetails .price .price--content,
    .DeviceGap .DeviceDetails .price .price--unity .price--currency {
      font-size: 15px !important;
    }
  }

  @media screen and (min-width: 768px) {
    .SupportAndSearchContainer {
      .ResultsSection .SearcherResultsGaps {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
      .linksListContainer {
        .results-t {
          display: block;
        }
      }
    }
    .SuggestedItem {
      height: auto;
    }
    .itemLink .itemLink-element,
    .ResultsSection.page
      .PageResultsContainer
      .SearcherResultsGaps
      .itemLink
      .itemLink-element {
      height: auto;
    }
  }
`;

class SupportAndSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  drawModal = () => {
    try {
      let positiontop = document.querySelector(
        ".header .header1-main"
      ).clientHeight;
      const modalParent = document.querySelector(".modal-root");
      modalParent.style.zIndex = "35";
      modalParent.style.height = `calc(100vh - ${parseInt(positiontop, 10)}px)`;
      modalParent.style.top = `${parseInt(positiontop, 10) - 1}px`;
      const modalContent = document.querySelector(".Modal_content");
      modalContent.style.overflow = "hidden";
    } catch (err) {
      console.error(err);
    }
  };

  componentDidMount() {
    this.drawModal();
    window.addEventListener("resize", this.drawModal);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.drawModal);
    // deshago el desastre del css
    const modalParent = document.querySelector(".modal-root");
    modalParent.style.removeProperty("z-index");
    modalParent.style.removeProperty("top");
    modalParent.style.removeProperty("height");
    const modalContent = document.querySelector(".Modal_content");
    modalContent.style.overflow = "hidden";
    modalContent.style.removeProperty("overflow");
    // restablezco valores iniciales
    const { resetSearcher } = this.props;
    resetSearcher();
  }

  go2AT = ({ label }) => {
    document.getElementById("hidden-query").value = "1";
    handleAtRequest({
      label,
      checkAt: false,
      checkPages: false,
      event: null,
    });
  };

  resultOnClick = ({ pathName, taggingInfo }) => {
    const { inputQuery } = this.props;
    const name = taggingInfo.componentName;
    const content = encodeURIComponent(inputQuery);
    const variation = taggingInfo.componentVariation;

    // Separar la URL en ancla y parametros
    let [baseUrl, hashFragment] = pathName.split("#");
    let [path, existingParams] = baseUrl.split("?");

    // Parámetros de búsqueda
    const newParams = `name=${name}&content=${content}&variation=${variation}`;

    const combinedParams = existingParams
      ? `${existingParams}${
          existingParams[existingParams.length - 1] !== "&" ? "&" : ""
        }${newParams}`
      : newParams;
    const finalUrl = `${path}?${combinedParams}${
      hashFragment ? `#${hashFragment}` : ""
    }`;

    // Crear un enlace temporal para poder extraer el dominio
    const tempLink = document.createElement("a");
    tempLink.href = finalUrl;
    const domain = tempLink.hostname;

    if (domain === "movistar.es" || domain === "www.movistar.es") {
      // Abrir en la misma pestaña
      window.location.href = finalUrl;
    } else {
      // Abrir en una nueva pestaña
      window.open(finalUrl, "_blank");
    }
  };

  /*
    kind: 'servicios' or 'devicepage', 'default
  */
  renderResultsLinks = (kind, results) => {
    const { inputQuery } = this.props;
    if (!results || results.length === 0) {
      return null;
    }
    let titleLabel = "";
    switch (kind) {
      case DEVICE:
        titleLabel = DEVICE_TITLE;
        break;
      case SERVICE:
        titleLabel = SERVICE_TITLE;
        break;
      default:
        titleLabel = PAGE_TITLE;
        break;
    }

    return (
      <>
        <TextInfo
          className={
            kind === SERVICE
              ? "results-t results-service"
              : "results-t results-device"
          }
          text={titleLabel}
          {...TITLE_RESULT_SECTION_PROPS}
          cyData={`Header modal ${titleLabel}`}
        />
        {results && Array.isArray(results) && (
          <div
            className="SearcherResultsGaps"
            data-cy={`Header searcher results ${titleLabel}`}
          >
            {results.map((pageElement) => {
              let { pathName, key, label } = pageElement;
              if (!key) {
                key = label;
              }
              const ariaLabelTitle = `Visitar ${pathName}`;
              const currentComponentContent = `${pathName}|${inputQuery}`;
              if (kind === SERVICE) {
                const { name: productName, amount: priceProduct } = pageElement;
                const currentTaggingInfo = {
                  componentProduct: "",
                  componentName: "searcher",
                  componentContent: currentComponentContent,
                  componentVariation: SERVICE,
                };
                return (
                  <CustomLink
                    key={productName}
                    title={ariaLabelTitle}
                    ariaLabel={ariaLabelTitle}
                    className="itemLink"
                    onClick={() =>
                      this.resultOnClick({
                        pathName,
                        taggingInfo: currentTaggingInfo,
                      })
                    }
                    taggingInfo={currentTaggingInfo}
                    forceExternal
                  >
                    <div className="ServiceGap">
                      <div className="ServiceDetails">
                        <div className="DeviceName">
                          <TextInfo
                            {...RESULT_TEXT_INFO_PROPS}
                            size="caption"
                            align="left"
                            display="block"
                            kind="paragraph"
                            text={productName}
                          />
                        </div>
                        <TextInfo
                          {...RESULT_TEXT_INFO_PROPS}
                          size="caption"
                          color={TEXT_COLOR.GREY4}
                          align="left"
                          className="prePriceText"
                          display={TEXT_DISPLAY.BLOCK}
                          text="Desde "
                        />
                        <Price
                          {...DEVICE_PRICE}
                          color="dark-blue"
                          fontType="font-bold"
                          layout="default"
                          currency={
                            pageElement.unity ? pageElement.unity : "€/mes"
                          }
                          price={priceProduct}
                        />
                      </div>
                    </div>
                  </CustomLink>
                );
              } else {
                // significa que es un dispositivo
                const picture2Show = pageElement.img;
                const currentTaggingInfo = {
                  componentProduct: "",
                  componentName: "searcher",
                  componentContent: currentComponentContent,
                  componentVariation: DEVICE,
                };
                return (
                  <CustomLink
                    key={key}
                    title={ariaLabelTitle}
                    ariaLabel={ariaLabelTitle}
                    className="itemLink"
                    onClick={() =>
                      this.resultOnClick({
                        pathName,
                        taggingInfo: currentTaggingInfo,
                      })
                    }
                    taggingInfo={{
                      componentProduct: "",
                      componentName: "searcher",
                      componentContent: currentComponentContent,
                      componentVariation: DEVICE,
                    }}
                    forceExternal
                  >
                    <div className="DeviceGap">
                      <div className="DevicePicture">
                        <Image
                          src={`https://prod.cms.col.movistar.es${picture2Show}`}
                          alt={pageElement.name}
                          fill
                          objectFit="contain"
                        />
                      </div>
                      <div className="DeviceDetails">
                        <div className="DeviceName">
                          <TextInfo
                            {...RESULT_TEXT_INFO_PROPS}
                            size="caption"
                            align="left"
                            display="block"
                            text={pageElement.name}
                            kind="paragraph"
                          />
                        </div>
                        <TextInfo
                          {...RESULT_TEXT_INFO_PROPS}
                          size="caption"
                          align="left"
                          className="prePriceText"
                          text={pageElement.prePrice}
                          color={TEXT_COLOR.GREY4}
                          display={TEXT_DISPLAY.BLOCK}
                        />
                        <Price
                          {...DEVICE_PRICE}
                          color="dark-blue"
                          fontType="font-bold"
                          layout="default"
                          currency={pageElement.unity}
                          price={pageElement.amount}
                        />
                      </div>
                    </div>
                  </CustomLink>
                );
              }
            })}
          </div>
        )}
      </>
    );
  };

  renderAutogeneratedPagesAndAv = () => {
    const { querySuggestedInputs, categoriesMatched, inputQuery } = this.props;
    let finalData2Render = [];
    if (querySuggestedInputs && querySuggestedInputs.length > 0) {
      finalData2Render = [...querySuggestedInputs];
    }
    if (categoriesMatched && categoriesMatched.length > 0) {
      finalData2Render = [
        ...categoriesMatched.slice(0, 3),
        ...finalData2Render,
      ];
    }
    finalData2Render = finalData2Render.slice(0, 3);

    if (finalData2Render && finalData2Render.length > 0) {
      return (
        <div className={`ResultsSection ${PAGE}`}>
          <div className="PageResultsContainer">
            <div
              className="SupportAndSearchContainer"
              data-cy="Header modal search support"
            >
              {finalData2Render.map((pageElement) => {
                let { key, label } = pageElement;
                let ariaLabelTitle = `Buscar ${label}`;
                let currentTaggingInfo = {
                  componentProduct: "",
                  componentName: "searcher",
                  componentContent: `${label}|${inputQuery}`,
                  componentVariation: "asistente-virtual",
                };

                let onClick = () =>
                  this.go2AT({
                    label,
                    taggingInfo: currentTaggingInfo,
                  });
                if (pageElement.kind === "page") {
                  const { pathName } = pageElement;
                  ariaLabelTitle = `Visitar ${pathName}`;
                  currentTaggingInfo = {
                    ...currentTaggingInfo,
                    componentContent: `https://www.movistar.es${pathName}|${inputQuery}`,
                    componentVariation: PAGE,
                  };
                  onClick = () =>
                    this.resultOnClick({
                      pathName,
                      taggingInfo: currentTaggingInfo,
                    });
                }

                let title = ariaLabelTitle;
                let ariaLabel = ariaLabelTitle;

                return (
                  <CustomLink
                    key={key}
                    className="itemLink"
                    onClick={onClick}
                    title={title}
                    ariaLabel={ariaLabel}
                    taggingInfo={currentTaggingInfo}
                    forceExternal
                  >
                    <div className="itemLink-element">
                      <div className="itemLink-element-icon">
                        <IconGlyph
                          id="cheuron-right-m"
                          height={8}
                          color="grey6"
                        />
                      </div>
                      <TextInfo
                        {...RESULT_TEXT_INFO_PROPS}
                        text={label}
                        size="body1"
                        color="blue"
                      />
                    </div>
                  </CustomLink>
                );
              })}
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  render() {
    const { props, state } = this;
    const {
      kind,
      querySuggestedInputs,
      pagesSuggestedList,
      resetSearcher,
      visibleInput,
      inputQuery,
      categoriesMatched,
      pagesNoResults,
    } = props;
    const { shouldSendAT } = state;
    const myProps = {};

    myProps.handleSearch = null;
    if (props.urlSupport) {
      myProps.handleSearch = (evt) => {
        this.handleAtRequest({
          evt,
          shouldSendAT: true,
          taggingInfo: taggingInfo2AT,
        });
      };
    }

    const checkIfThereAreNoResults =
      !pagesSuggestedList ||
      (pagesSuggestedList.regular &&
        pagesSuggestedList.regular.length === 0 &&
        pagesSuggestedList.services &&
        pagesSuggestedList.services.length === 0 &&
        pagesSuggestedList.devices &&
        pagesSuggestedList.devices.length === 0);

    const checkNoATLinks =
      !querySuggestedInputs || querySuggestedInputs.length === 0;

    return (
      <>
        <StyledSupportAndSearch
          className="SupportAndSearch"
          data-cy="Header modal search"
          role="dialog"
          aria-label="Resultados de la búsqueda"
        >
          <div className={`SupportAndSearchContainer ${kind}`}>
            {visibleInput && (
              <div className="SearcherGap">
                <SearchInput
                  id={INPUT_ID}
                  autoComplete="off"
                  className="mobile"
                  handleSearch={myProps.handleSearch}
                  placeHolder="Buscar en Movistar"
                  shouldSendAT={shouldSendAT}
                  backButton
                  autoFocus
                  taggingInfo={taggingInfo2AT}
                  onClear={resetSearcher}
                  ignoreParamDesktop={true}
                />
              </div>
            )}
            {pagesSuggestedList &&
              pagesSuggestedList.regular &&
              pagesSuggestedList.regular.length > 0 && (
                <div
                  className="SuggestedInput"
                  data-cy="Header modal search suggestions"
                >
                  {pagesSuggestedList.regular.map((item) => {
                    const { pathName, key, name } = item;
                    const ariaLabel = `Visitar ${pathName}`;
                    const currentTagginInfo = {
                      ...taggingInfo2AT,
                      componentContent: `${name}|${inputQuery}`,
                      componentVariation: PAGE,
                    };
                    return (
                      <CustomLink
                        key={key}
                        onClick={() => {
                          this.resultOnClick({
                            pathName,
                            taggingInfo: currentTagginInfo,
                          });
                        }}
                        title={ariaLabel}
                        ariaLabel={ariaLabel}
                        taggingInfo={currentTagginInfo}
                      >
                        <div className="SuggestedItem">
                          <IconGlyph id="buscar-m" height={16} color="blue" />
                          <TextInfo
                            className="suggested-label"
                            {...RESULT_TEXT_INFO_PROPS}
                            key={key}
                            text={name}
                            color="blue"
                          />
                        </div>
                      </CustomLink>
                    );
                  })}
                </div>
              )}
            {/* LINKS AREA */}
            {pagesSuggestedList &&
              (pagesSuggestedList.devices.length > 0 ||
                pagesSuggestedList.services.length > 0) && (
                <div className="linksListContainer">
                  {/* DIBUJAMOS DATOS DE LA BUSQUEDA */}
                  {pagesSuggestedList &&
                    pagesSuggestedList.devices &&
                    pagesSuggestedList.devices.length > 0 && (
                      <div className={`ResultsSection ${DEVICE}`}>
                        {this.renderResultsLinks(
                          DEVICE,
                          pagesSuggestedList.devices
                        )}
                      </div>
                    )}
                  {pagesSuggestedList &&
                    pagesSuggestedList.services &&
                    pagesSuggestedList.services.length > 0 && (
                      <div className={`ResultsSection ${SERVICE}`}>
                        {this.renderResultsLinks(
                          SERVICE,
                          pagesSuggestedList.services
                        )}
                      </div>
                    )}
                </div>
              )}
          </div>
          {this.renderAutogeneratedPagesAndAv()}

          {checkIfThereAreNoResults &&
            checkNoATLinks &&
            inputQuery.length > 2 && (
              <>
                <div className="SupportAndSearchContainer">
                  <div className="SearcherNoResults">
                    <TextInfo
                      kind="p"
                      text="Lo sentimos, no hemos encontrado lo que buscas"
                      size="t6"
                      color="grey6"
                      fontType="font-bold"
                    />
                    <TextInfo
                      kind="p"
                      text="Comprueba la ortografía o intenta una búsqueda con términos más generales"
                      size="body1"
                      color="grey4"
                    />
                  </div>
                </div>
                <div className={`ResultsSection page`}>
                  <div className="PageNoResultsContainer">
                    <div
                      className="SupportAndSearchContainer"
                      data-cy="Header modal search support"
                    >
                      {pagesNoResults &&
                        pagesNoResults.map((item, index) => {
                          let { text, link } = item;
                          const ariaLabelTitle = `Visitar ${link}`;
                          const currentTaggingInfo = {
                            componentProduct: "",
                            componentName: "searcher",
                            componentContent: inputQuery,
                            componentVariation: "suggested-link",
                          };

                          return (
                            <CustomLink
                              key={index}
                              className="itemLink"
                              title={text}
                              ariaLabel={ariaLabelTitle}
                              taggingInfo={currentTaggingInfo}
                              onClick={() =>
                                this.resultOnClick({
                                  pathName: link,
                                  taggingInfo: currentTaggingInfo,
                                })
                              }
                              forceExternal
                            >
                              <div className="itemLink-element">
                                <div className="itemLink-element-icon">
                                  <IconGlyph
                                    id="cheuron-right-m"
                                    height={8}
                                    color="grey6"
                                  />
                                </div>
                                <TextInfo
                                  {...RESULT_TEXT_INFO_PROPS}
                                  text={text}
                                  size="body1"
                                  color="blue"
                                />
                              </div>
                            </CustomLink>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </>
            )}
        </StyledSupportAndSearch>
      </>
    );
  }
}

SupportAndSearch.propTypes = {
  visibleInput: PropTypes.bool,
};

SupportAndSearch.defaultProps = {
  visibleInput: true,
};

const mapStateToProps = (state) => ({
  categoriesMatched: state.header.categoriesMatched,
  querySuggestedInputs: state.header.querySuggestedInputs,
  pagesSuggestedList: state.header.pagesSuggestedList,
  pagesNoResults: state.header.pagesNoResults,
  inputQuery: state.header.inputQuery,
});

const mapDispatchToProps = (dispatch) => ({
  sendCmsEvent: (data) => dispatch(mainActions.sendCmsEvent(data)),
  addModal: (data) => dispatch(modalActions.addModal(data)),
  getSearcherInfo: () => dispatch(headerActions.getSearcherInfo()),
  getSuggestedInputs: (query) =>
    dispatch(headerActions.getSuggestedInputs(query)),
  getRelatedPages: (query) => dispatch(headerActions.getRelatedPages(query)),
  resetSearcher: () => dispatch(headerActions.resetSearcher()),
  throwSonda: (data) => dispatch(mainActions.throwSonda(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SupportAndSearch);

import React from "react";
import dynamic from "next/dynamic";

const Bubble = dynamic(() => import("./bubble"));

const NotificationBubbleWrapper = ({
  children,
  disabled,
  maxNum = 4,
  notifications,
  noNumber,
  top = 0,
  right = 0,
  size = 8,
  fontSize = 10,
}) => {
  if (disabled) {
    return <>{children}</>;
  }

  return (
    <div className="notification-wrapper">
      {!!notifications && (
        <div
          className="bubble-wrapper"
          style={{ top: `${top}px`, right: `${right}px` }}
        >
          <Bubble
            maxNum={maxNum}
            notification={notifications}
            size={size}
            fontSize={fontSize}
            noNumber={noNumber}
          />
        </div>
      )}

      {children}
      <style jsx>{`.notification-wrapper {
  position: relative;

  .bubble-wrapper {
    position: absolute;
    transform: translate(50%, -50%);
    z-index: 10;
  }
}
`}</style>
    </div>
  );
};

export default NotificationBubbleWrapper;

import React from "react";
import PropTypes from "prop-types";
import Text, {
  TEXT_COLOR,
  TEXT_SIZE,
  TEXT_FONT_WEIGHT,
  TEXT_SEGMENT,
  TEXT_ALIGN,
} from "@/bit/components/components.text";
import { COLOR_DICTIONARY } from "@gcloud-npm/utils.enums.colors";
import Glyph from "@/bit/components/components.icon-glyph";

const LinkGroupHeader = ({ title, onClick, isOpen }) => {
  return (
    <>
      <div className="link-header" onClick={onClick}>
        <div className="title-container">
          <Text
            text={title}
            color={TEXT_COLOR.BLUE_DARK}
            size="subtitle1"
            fontType={TEXT_FONT_WEIGHT.BOLD}
            align={TEXT_ALIGN.LEFT}
            cyData={`Footer ${title}`}
          />
        </div>

        <div className="arrow-icon" data-cy={`Footer arrow ${title}`}>
          {isOpen ? (
            <Glyph id="cheuron-up-m" width={14} color={COLOR_DICTIONARY.BLUE} />
          ) : (
            <Glyph
              id="cheuron-down-m"
              width={14}
              color={COLOR_DICTIONARY.BLUE}
            />
          )}
        </div>
      </div>
      <style jsx>{`.link-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.link-header .title-container {
  min-height: 3rem;
  display: flex;
  align-items: center;
}

.arrow-icon {
  margin-right: 1rem;
}

@media screen and (min-width: 768px) {
  .link-header {
    display: block;
  }

  .link-header .title-container {
    display: block;
    min-height: 3.125rem;
  }

  .link-header .arrow-icon {
    display: none;
  }
}
@media screen and (min-width: 1024px) {
  .link-header {
    display: block;
    margin-bottom: 0;
  }

  .link-header .title-container {
    display: block;
  }

  .link-header .arrow-icon {
    display: none;
  }
}
`}</style>
    </>
  );
};

export default LinkGroupHeader;

LinkGroupHeader.propTypes = {
  /**
   * {
   *  "info": "Texto del título de el bloque de enlaces",
   *  "kind": "both",
   *  "beautifulName": "Título del bloque"
   * }
   */
  title: PropTypes.string,

  /**
   * {
   *  "info": "Función que se ejecuta cuando cliqueas en la cabecera",
   *  "kind": "both",
   *  "beautifulName": "Función on click"
   * }
   */
  onClick: PropTypes.func,

  /**
   * {
   *  "info": "Booleano que muestra si los enlaces anidados de la cabecera están desplegados",
   *  "kind": "both",
   *  "beautifulName": "Enlaces desplegados"
   * }
   */
  isOpen: PropTypes.bool,
};

LinkGroupHeader.defaultProps = {
  title: "",
  onClick: () => {},
  isOpen: false,
};

import React from "react";
import PropTypes from "prop-types";
import MediaIcon from "./media-icon";
import Text, { TEXT_COLOR } from "@/bit/components/components.text";

const SocialMedia = ({ data }) => {
  return (
    <>
      <div className="social-media">       
        <ul className="social-media-logos" data-cy="Footer social media logos">
          {data &&
            data.map((e, i) => (
              <MediaIcon
                icon={e?.icon}
                link={e?.link}
                target={e?.target}
                key={`${e?.icon}-item.${i}`}
                ariaLabel={e?.ariaLabel}
                title={e?.title}
              />
            ))}
        </ul>
      </div>
      <style jsx>{`.social-media {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;  
}
.social-media .social-media-logos {
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;  
}
.social-media .social-media-logos :global(img:hover){
  opacity: 0.75;
}
@media screen and (min-width: 768px) {
  .social-media {
    padding: 0;
  }

  .social-media .social-media-logos {
    padding: 0;
    gap: 3.125rem;    
  }
  .social-media .siguenos {
    display: none;
  }
}

@media screen and (min-width: 1024px) { 
  .social-media .social-media-logos {   
    gap: 5.5rem;    
  }  
}
`}</style>
    </>
  );
};

export default SocialMedia;

SocialMedia.propTypes = {
  /**
   * {
   *  "info": "Lista compuesta por el id de los iconos, links y targets asociados",
   *  "kind": "both",
   *  "beautifulName": "Lista de ids de iconos, links y targets"
   * }
   */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      link: PropTypes.string,
      target: PropTypes.string,
      title: PropTypes.string,
      ariaLabel: PropTypes.string,
    })
  ),
};

SocialMedia.defaultProps = {
  data: [],
};

import React from "react";
import PropTypes from "prop-types";
import Text, {
  TEXT_COLOR,
  TEXT_SIZE,
  TEXT_FONT_WEIGHT,
} from "@/bit/components/components.text";
import CustomLink from "@/bit/components/components.custom-link";
import { clickCookies } from "@/bit/components/components.one-trust";
import { TAGGIN_INFO } from "../../constants";

const SecondaryLinkItem = ({ link, text, target, actionId, ariaLabel, title }) => {
  const onClick = () => {
    switch (actionId) {
      case "cookies":
        clickCookies({
          type: "settings",
          handlerStyles: true,
        });
        break;
    }
  };

  return (
    <CustomLink
      onClick={onClick}
      target={target}
      href={link}
      taggingInfo={{ ...TAGGIN_INFO, componentContent: text }}
      cyData={`Footer secondary link ${text}`}
      ariaLabel={ariaLabel}
      title={title}
    >
      <Text
        text={text}
        color={TEXT_COLOR.GREY3}
        size="caption"
        weigh={TEXT_FONT_WEIGHT.REGULAR}
      />
    </CustomLink>
  );
};

export default SecondaryLinkItem;

SecondaryLinkItem.propTypes = {
  /**
   * {
   *  "info": "URL del link",
   *  "kind": "both",
   *  "beautifulName": "URL del link"
   * }
   */
  link: PropTypes.string,

  /**
   * {
   *  "info": "Texto del link",
   *  "kind": "both",
   *  "beautifulName": "Texto del link"
   * }
   */
  text: PropTypes.string,

  /**
   * {
   *  "info": "Target de la URL",
   *  "kind": "both",
   *  "beautifulName": "Target de la URL"
   * }
   */
  target: PropTypes.oneOf(["_blank", "_self", "_parent", "_top"]),

  /**
   * {
   *  "info": "Función que se ejecuta cuando se clica en el componente",
   *  "kind": "both",
   *  "beautifulName": "Función ejecutada al clicar"
   * }
   */
  onClick: PropTypes.func,
};

SecondaryLinkItem.defaultProps = {
  link: "",
  text: "",
  target: "_self",
  onClick: () => {},
};

import React from "react";
import PropTypes from "prop-types";
import CustomLink from "@/bit/components/components.custom-link";
import { COLOR_DICTIONARY } from "@gcloud-npm/utils.enums.colors";
import Glyph from "@/bit/components/components.icon-glyph";
import { TAGGIN_INFO } from "../../constants";

const MediaIcon = ({ icon, link, target, ariaLabel, title }) => {
  const getGlyph = (id) => {
    const icon = id.toLowerCase();
    switch (icon) {
      case "facebook":
        return (
          <Glyph
            id="facebook"
            width={14.53}
            height={28}
            color={COLOR_DICTIONARY.GREY3}
          />
        );

      case "twitter":
        return (
          <Glyph
            id="twitter"
            width={28}
            height={26}
            color={COLOR_DICTIONARY.GREY3}
          />
        );

      case "youtube":
        return (
          <Glyph
            id="youtube"
            width={28}
            height={20.22}
            color={COLOR_DICTIONARY.GREY3}
          />
        );

      case "instagram":
        return (
          <Glyph id="instagram" width={28} color={COLOR_DICTIONARY.GREY3} />
        );

      case "linkedin":
        return (
          <Glyph id="linkedin" width={28} color={COLOR_DICTIONARY.GREY3} />
        );

      case "whatsapp":
        return (
          <Glyph id="whatsapp-m" width={28} color={COLOR_DICTIONARY.GREY3} />
        );

      case "comunidad":
          return (
            <Glyph id="miembros-m" width={28} color={COLOR_DICTIONARY.GREY3} />
          );
          
      default:
        return <Glyph id={icon} width={28} color={COLOR_DICTIONARY.GREY3} />;
    }
  };

  return (
    <li>
      <CustomLink
        href={link}
        taggingInfo={{ ...TAGGIN_INFO, componentContent: icon }}
        target={target}
        ariaLabel={ariaLabel}
        title={title}
      >
        {getGlyph(icon)}
      </CustomLink>
    </li>
  );
};

export default MediaIcon;

MediaIcon.propTypes = {
  /**
   * {
   *  "info": "Id del icono que se quiere pintar",
   *  "kind": "both",
   *  "beautifulName": "ID del icono"
   * }
   */
  icon: PropTypes.string,

  /**
   * {
   *  "info": "Link asociado al icono",
   *  "kind": "both",
   *  "beautifulName": "Link del icono"
   * }
   */
  link: PropTypes.string,

  /**
   * {
   *  "info": "Etiqueta HTML target que se incluye en el link",
   *  "kind": "both",
   *  "beautifulName": "Target"
   * }
   */
  target: PropTypes.oneOf(["_blank", "_self", "_parent", "_top"]),
  title: PropTypes.string,
  ariaLabel: PropTypes.string,
};

MediaIcon.defaultProps = {
  icon: "",
  target: "_self",
};

import React from "react";
import Logo from "../../logo";
import Searcher from "../../searcher";
import UserPill from "../../user-pill";
import BurgerMenu from "../../menu/layouts/burger";
import IconsMenu from "../../menu/layouts/icons";
import { MENU_LAYOUTS } from "../../textConstans";

const Header1Main = (props) => {
  const {
    logged,
    userName,
    userType,
    mainOptions,
    segmentMenu,
    burgerSections,
    contextLoaded,
    burgerOptions,
    accountSections,
    logoutBackToHome,
    privateArea,
    forceSelect,
    location,
  } = props;
  const selector = logged ? "logged" : "default";

  if (selector === "logged") {
    mainOptions[selector].notifications = {
      text: "Notificaciones",
      "icon-id": "campana",
      target_link: "",
      link: "https://tiendas.movistar.es",
      "link-target": null,
      "action-id": "notifications",
      taggingInfo: {
        componentName: "header",
        componentContent: "notifications",
        componentVariation: "cliente",
      },
    };
  }

  return (
    <div className="container">
      <div className="header1-main">
        {burgerSections && burgerSections[selector] && (
          <BurgerMenu
            layout={MENU_LAYOUTS.BURGER}
            items={burgerSections[selector]}
            segmentMenu={segmentMenu[selector]}
            options={burgerOptions[selector]}
            account={accountSections}
            privateArea={privateArea}
            forceSelect={forceSelect}
            logged={logged}
            location={location}
          />
        )}
        <div className="logo">
          <Logo />
        </div>
        <div className="searcher">
          <Searcher needOpenModalResults />
        </div>
        <div className="main-left">
          <div className="options">
            {contextLoaded && mainOptions && mainOptions[selector] && (
              <IconsMenu
                items={mainOptions[selector]}
                layout={MENU_LAYOUTS.ICONS}
                location="all"
                privateArea={privateArea}
              />
            )}
          </div>
          {contextLoaded && logged && (
            <UserPill
              userName={userName}
              userType={userType}
              items={accountSections}
              logoutBackToHome={logoutBackToHome}
              privateArea={privateArea}
            />
          )}
        </div>
        <style jsx>{`.header1-main {
  position: relative;
  z-index: 50;
  display: flex;
  align-items: center;
  height: 3.5rem;
  background-color: #fff;
  margin: 0 auto;

  .main-left {
    display: flex;
    margin-left: 1rem;
  }
  :global(.menu-m) {
    :global(img) {
      max-width: unset;
    }
  }
  :global(.menu) {
    :global(.button) {
      display: flex;
      cursor: pointer;
    }
    :global(.button:hover) {
      opacity: 0.5;
    }
    :global(.button:focus) {
      outline: none;
    }
    :global(.modal) {
      position: absolute;
      z-index: 10;
      background-color: #fff;
      display: none;
    }
  }

  .logo {
    margin-left: 4.88%;
    min-width: 7rem;
  }
  .searcher {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .options {
    display: flex;
    justify-content: center;
    align-items: stretch;

    :global(.menu-item-link) {
      min-width: 35px;

      :global(.menu-item) {
        height: 100%;
        align-items: center;
        display: flex;
        flex-flow: column;
        justify-content: center;
      }
    }

    gap: 1rem;
    :global(.icon-gap) {
      text-align: center;
    }
    :global(.text-gap) {
      display: none;
    }
    :global(.menu-item) {
      cursor: pointer;
      min-width: 25px;
      &:hover {
        opacity: 0.5;
      }
      :global(.usuario-m) {
        position: relative;
        top: 2px;
        :global(img) {
          max-width: unset;
        }
      }
    }
    :global(.menu-item-link.Tiendas) {
      display: none;
    }
  }
}

@media screen and (min-width: 768px) {
  .header1-main {
    height: 3.75rem;
    .main-left {
      align-items: center;
      min-width: 80px;
      display: flex;
      justify-content: flex-end;
    }
    .options {
      gap: 1rem;

      :global(.menu-item-link) {
        min-width: unset;

        :global(.menu-item) {
          align-items: stretch;
          justify-content: space-between;
        }

        :global(.menu-item.campana) {
          :global(.icon-gap) {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      :global(.menu-item-link.Tiendas) {
        display: block;
      }
    }
    .searcher {
      width: 100%;
    }
  }
}
@media screen and (min-width: 1024px) {
  .header1-main {
    .logo {
      max-width: 520px;
    }
    .searcher {
      margin-left: 5.883%;
    }
    .main-left {
      min-width: unset;
      display: flex;
      justify-content: flex-end;
    }
    .options {
      :global(.menu-item-link) {
        min-width: unset;

        :global(.menu-item) {
          align-items: stretch;
          justify-content: space-between;
        }
      }

      :global(.icon-gap) {
        :global(svg) {
          vertical-align: middle;
        }
      }
      :global(.text-gap) {
        display: block;
      }
    }
  }
}
@media screen and (min-width: 1280px) {
  .header1-main {
    height: 5rem;
    .logo {
      margin-left: 3.268%;
      max-width: 520px;
      height: 2rem;
      min-width: 8.375rem;
    }

    .main-left {
      min-width: 368px;
      display: flex;
      justify-content: flex-end;
    }
  }
}
`}</style>
      </div>
    </div>
  );
};
export default Header1Main;
